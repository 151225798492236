import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GameTease, Image, Link, SEO } from '@src/components';
import styles from './styles/games.module.scss';

const CURRENT_DATE = new Date();

const sortGames = (aGame, bGame) => {
  const aTime = aGame.timeline;
  const aFrom = aTime && aTime.from && new Date(aTime.from);
  const aTo = (aTime && aTime.to && new Date(aTime.to)) || CURRENT_DATE;

  const bTime = bGame.timeline;
  const bFrom = bTime && bTime.from && new Date(bTime.from);
  const bTo = (bTime && bTime.to && new Date(bTime.to)) || CURRENT_DATE;

  if (aTo > bTo) {
    return -1;
  } else if (aTo < bTo) {
    return 1;
  } else if (aFrom > bFrom) {
    return -1;
  } else if (aFrom < bFrom) {
    return 1;
  }
  return aGame.title.localeCompare(bGame.title);
};

const GamesPage = ({ data: { allGames, page } }) => {
  const hasFeaturedGames = !!(page.featuredGames && page.featuredGames.length);

  const ninSet = useMemo(() => {
    if (!hasFeaturedGames) {
      return new Set();
    }
    return new Set(page.featuredGames.map(game => game._id));
  }, [hasFeaturedGames]);

  const orderedGames = useMemo(() => {
    const games = allGames.edges.reduce((acc, { node }) => {
      if (ninSet.has(node._id)) {
        return acc;
      }
      return [...acc, node];
    }, []);
    return games.sort(sortGames);
  }, [ninSet, allGames]);

  const featuredGames = hasFeaturedGames ? page.featuredGames : orderedGames.slice(0, 2);
  const gamesList = hasFeaturedGames ? orderedGames : orderedGames.slice(2);

  return (
    <Fragment>
      <SEO seo={page.seo} title={page.title} />
      <h1 className={styles.title}>{page.title}</h1>
      <section className={styles.section}>
        <h3 className={styles.label}>Featured</h3>
        <ul className={styles.featuredList}>
          {featuredGames.map(({ _id, slug, title, preview, summary }) => (
            <li key={_id} className={styles.featured}>
              <Link
                className={styles.featuredLink}
                to={`/games/${slug.current}`}
                labelledBy={slug.current}>
                <div className={styles.preview}>
                  <div className={styles.previewInner}>
                    <Image className={styles.previewContent} value={preview} />
                  </div>
                </div>
                <div className={styles.featuredMeta}>
                  <p id={slug.current} className={styles.featuredTitle}>
                    {title}
                  </p>
                  <p className={styles.featuredSummary}>{summary}</p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </section>
      <section className={styles.section}>
        <h3 className={styles.label}>All games</h3>
        <ul className={styles.teaseList}>
          {gamesList.map(game => (
            <li key={game._id} className={styles.teaseItem}>
              <GameTease game={game} />
            </li>
          ))}
        </ul>
      </section>
    </Fragment>
  );
};

GamesPage.propTypes = {
  data: PropTypes.shape({
    allGames: PropTypes.object,
    page: PropTypes.object,
  }).isRequired,
};

export default GamesPage;

export const query = graphql`
  query GamesPageQuery {
    page: sanityGamesPage {
      title
      featuredGames {
        ...GameTease
      }
      seo {
        ...SanitySeo
      }
    }

    allGames: allSanityGame(sort: { fields: timeline___to, order: DESC }) {
      edges {
        node {
          ...GameTease
        }
      }
    }
  }
`;
